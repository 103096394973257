<template>
	<div
		:id="blockId"
		ref="blogList"
		v-qa="'builder-section-blog'"
		class="block-blog-list"
	>
		<div
			v-if="filteredCategoryId"
			class="block-blog-list__filter"
		>
			{{ blogCategories[filteredCategoryId].name }}
			<button
				class="block-blog-list__filter-button"
				@click="filteredCategoryId = null"
			>
				{{ SHOW_ALL_POSTS_LABEL }}
			</button>
		</div>
		<div
			v-if="currentPageItems.length"
			class="block-blog-list__list"
			:class="{ 'block-blog-list__list--one-col': postColumnCount === 1 }"
		>
			<BlockBlogListItem
				v-for="(post, index) in currentPageItems"
				:key="`post-${index}`"
				v-qa="'blog-list-item'"
				:post="post"
				:author-name="post.meta.authorName"
				:cover-object-fit="data.settings.styles['cover-object-fit']"
				:shown-items="data.settings.shownItems"
				:blog-categories="blogCategories"
				:is-animation-active="isAnimationActive"
				:blog-reading-time-text="blogReadingTimeText"
				:blog-title-text-size="blogTitleTextSize"
				@filter-category="(filteredCategoryId = $event)"
				@post-click="emit('post-click', post)"
			>
				<template #block-blog-list-item-overlay>
					<slot
						name="block-blog-list-overlay"
						:post="post"
					/>
				</template>
			</BlockBlogListItem>
		</div>
		<div
			v-else
			class="block-blog-list__empty-block"
		>
			<slot name="block-blog-list-empty-block" />
		</div>
		<ZyroPagination
			class="block-blog-list__pagination"
			:current-page="currentPage"
			:page-count="pageCount"
			@change-page="setCurrentPage"
		/>
	</div>
</template>

<script setup>
import { scrollToSection } from '@zyro-inc/site-modules/utils/scrollToSection';

import ZyroPagination from '@zyro-inc/site-modules/components/ZyroPagination.vue';
import BlockBlogListItem from '@zyro-inc/site-modules/components/blocks/blog/BlockBlogListItem.vue';
import {
	ref,
	onMounted,
	onBeforeUnmount,
	computed,
	watch,
} from 'vue';

const SHOW_ALL_POSTS_LABEL = 'Show all posts';
const BLOG_LIST_PADDING_X = 46;

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		required: true,
	},
	lcp: {
		type: Object,
		default: () => ({}),
	},
	posts: {
		type: Object,
		default: () => ({}),
	},
	blogCategories: {
		type: Object,
		default: () => ({}),
	},
	blogReadingTimeText: {
		type: [
			String,
			undefined,
		],
		default: undefined,
	},
});

const emit = defineEmits(['post-click']);

const currentPage = ref(0);
const filteredCategoryId = ref(null);
const isAnimationActive = ref(false);

const postColumnCount = computed(() => Number.parseInt(props.data.settings.postColumnCount, 10));
const blogListPaddingXCssVar = computed(() => `${BLOG_LIST_PADDING_X}px`);
const BLOG_TITLE_FONT_SIZE_FALLBACK = 24;
const blogTitleTextSize = computed(() => props.data.settings.blogTitleFontSize || BLOG_TITLE_FONT_SIZE_FALLBACK);

// Create a posts object from original one, but exclude posts that don't have shown categories.
// or, if filtered category is set, show only filtered posts.
// If the main categories array is empty, show all posts.
const postsToRender = computed(() => {
	const {
		categories,
		showAllPosts,
		showWithoutCategories,
	} = props.data.settings;

	const posts = showAllPosts ? props.posts : Object.fromEntries(
		Object.entries(props.posts)
			.filter(([, page]) => {
				const isPostIncludedInList = categories.some(
					(listCategory) => page.categories.includes(listCategory),
				);
				const showUncategorized = showWithoutCategories && page.categories.length === 0;

				return showUncategorized || isPostIncludedInList;
			}),
	);

	if (filteredCategoryId.value) {
		return Object.fromEntries(Object.entries(posts)
			.filter(([, post]) => post.categories.includes(filteredCategoryId.value)));
	}

	return posts;
});

const activePostsToRender = computed(() => {
	const currentDate = new Date().setHours(0, 0, 0, 0);

	return Object.fromEntries(Object.entries(postsToRender.value)
		.filter(([, post]) => {
			const publishDate = new Date(post.date).setHours(0, 0, 0, 0);

			return publishDate && currentDate >= publishDate && !post.isDraft;
		}));
});

const sortedPosts = computed(() => Object.values(activePostsToRender.value).sort(
	(a, b) => Date.parse(b.date) - Date.parse(a.date),
));

const currentPageItems = computed(() => sortedPosts.value.slice(
	(currentPage.value > 0 ? currentPage.value - 1 : 0) * props.data.settings.postsPerPage,
	currentPage.value * props.data.settings.postsPerPage,
));

const pageCount = computed(() => Math.ceil(Object.keys(activePostsToRender.value).length / props.data.settings.postsPerPage));

const handleBrowserNavigationPageChange = () => {
	const params = new URLSearchParams(window.location.search);
	const pageFromParams = Number.parseInt(params.get('page'), 10) || 1;

	if (pageFromParams === currentPage.value) {
		return;
	}

	currentPage.value = pageFromParams;
};

const setCurrentPage = (newPage) => {
	currentPage.value = newPage;

	isAnimationActive.value = true;

	const url = new URL(window.location);

	url.searchParams.set('page', currentPage.value);

	window.history.pushState(null, '', url.toString());
	scrollToSection({
		linkToSection: `#${props.blockId}`,
	});
};

// If current page is not the first page and there are no items to show, go back one page
watch(currentPageItems, (newCurrentPageItems) => {
	if (newCurrentPageItems.length === 0 && currentPage.value > 1) {
		currentPage.value -= 1;
	}
});

watch(filteredCategoryId, () => {
	isAnimationActive.value = true;
});

onMounted(() => {
	const params = new URLSearchParams(window.location.search);
	const newPage = Number.parseInt(params.get('page'), 10) || 1;

	if (currentPage.value !== newPage) {
		currentPage.value = newPage;
	}

	window.addEventListener('popstate', () => {
		handleBrowserNavigationPageChange();
	});
});

onBeforeUnmount(() => {
	window.removeEventListener('popstate', handleBrowserNavigationPageChange);
});

</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.block-blog-list {
	z-index: $z-index-site-engine-block-grid;
	display: flex;
	flex-direction: column;
	grid-area: 1/1 / -1/-1;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	padding: var(--block-padding, 8px) v-bind(blogListPaddingXCssVar);

	&__list {
		display: grid;

		// Auto-fill makes columns wrap, but also doesn't allow to have more items than column count,
		grid-template-columns: repeat(v-bind(postColumnCount),  1fr);
		grid-gap: var(--grid-gap-size);
		width: var(--content-width);
		max-width: 100%;

		&--one-col {
			grid-template-columns: 1fr;
			width: calc(var(--content-width) / 2);
		}
	}

	&__filter {
		display: flex;
		flex-direction: column;
		text-align: center;

		&-button {
			font-size: 14px;
			font-weight: normal;
			line-height: 1.43;
			text-decoration: underline;
			text-transform: none;
			letter-spacing: 0;
			background-color: transparent;
			margin: 16px 0;
		}
	}

	&__pagination {
		align-self: center;
	}

	&__empty-block {
		display: flex;
		flex-direction: column;
		place-content: center;
		place-items: center;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
}

// Mobile layout
@include site-engine-mobile {
	.block-blog-list {
		padding: var(--block-padding, 8px) 16px;

		&__list {
			grid-template-columns: repeat(1, 1fr);
			grid-gap: var(--m-grid-gap-size);
			width: 100%;
		}
	}
}

</style>
